#root-content-wrapper-mobile{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    padding-left: 3%;
    padding-right: 5%;
    height: 80vh;
}
#biography-blurb-mobile{
    display: flex;
    flex-direction: column;
    overflow: auto;
    width: 100%;
    height: 75vh;
    grid-area: 1/1/2/2;
    text-align: center;
    font-family: 'Caveat', cursive;
    padding-right: 1%;
    
    img{
        width: 75%;
        filter: grayscale(1);
        opacity: 0.85;
        margin: 10%;
    }
    p{
        height: 40vh;
        margin: 5%;
        overflow-y: auto;
        padding: 5%;
        box-shadow: 0px 0px 9px -4px rgba(0,0,0,0.75);
    }

}
#mobile-portfolio-wrapper{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 0.8fr 1fr 10fr;
    grid-template-areas: 
        "header"
        "filterButtons"
        "portfolioItems";
    height: 50vh;
}
#portfolio-wrapper-mobile{
    display: flex;
    height: 75vh;
}
.mobile-portfolio-header{
    grid-area: header;
    padding: 3%;
    background-position: center;
    background-size: cover;
    box-shadow: 0px 0px 11px -2px rgba(0,0,0,0.75);
    border: 1px solid black;
    border-radius: 3px;
    color: #2d2d2e;
}
.mobile-portfolio-items-wrapper{
    grid-area: portfolioItems;
    overflow: auto;
    font-family: 'Caveat', cursive;
    font-size: 1.5em;
    filter: grayscale(1);
    box-shadow: 0px 0px 9px -4px rgba(0,0,0,0.75);
    margin: 3%;
    height: 55vh;
    a{
        color: black;
   
    }
    
    
}
.mobile-filter-button-wrapper{
    grid-area: filterButtons;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 5%;;
}
.mobile-portfolio-item{
    display: flex;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 50%;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.img-text-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 5px;
    color: #e1e4e8;
    background-image: url("../../../helper/images/backgrounds/darkpencilbackground.jpg");
    background-size: cover;
    background-position: center;
  }
  