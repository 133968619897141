.ReactModal__Content{
    display: flex;
    height: 100dvh;
    width: 92dvw;
    align-items: center;
    justify-content: center;
}
.ReactModal__Overlay{
    display: flex;
    align-items: center;
    justify-content: center;
}
.portfolio-modal-wrapper{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 0.01fr 1fr 0.1fr;
    grid-template-areas:
        "empty exit"
        "left-column right-column"
        "fillleft fillright";
    width: 76dvw;
    height: 85dvh;
    background-image: url('../../../helper/images/backgrounds/lightpencilbackground.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 1%;
    margin: 3%;
    align-items: center;
    justify-content: center;
    align-self: center;
    .portfolio-item-modal-exit{
        height: 3dvh;
        width: 40dvw;
        display: flex;
        grid-area: exit;
        justify-content: flex-end;
        font-size: x-large;
        align-self: flex-start;
    }
}
    