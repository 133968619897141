.about-wrapper{
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 0;
    .about-me-wrapper{
        height: 83%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
        grid-template-areas: "leftcol rightcol";
        padding-left: 20%;
        padding-right: 20%;
        padding-top: 2%;
        @media only screen and (max-aspect-ratio : 1.05){
            padding: 10%;
            padding-top: 0;
        }

        .about-left-column{
            width: 95%;
            height: 86vh;
            display: grid;
            grid-area: leftcol;
            overflow-y: auto;
            padding: 5%;
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: 1.3fr 2fr 1fr;
            gap: 5%;
            grid-template-areas: 
                "Biography"
                "Education"
                "Resume";
            
            @media only screen and (max-aspect-ratio : 1.05){
                height: 81.5vh;
                gap: 2%;
                grid-template-rows: 1.3fr 1.7fr 0.8fr; 
            }
                .about-biography{
                    grid-area: Biography;
                    height: 100%;
                    overflow-y: auto;
                    padding: 5%;
                    text-align: center;
                    box-shadow: 0px 0px 11px -2px rgba(0,0,0,0.75);                    
                }
                .about-biography.mobile{
                    font-size: 0.85em;
                }
                .about-education{
                    grid-area: Education;
                    overflow-y:auto;
                    height: 100%;
                    padding: 5%;
                    box-shadow: 0px 0px 11px -2px rgba(0,0,0,0.75);                }
                .about-additional{
                    grid-area: Resume;
                    display: flex;
                    flex-direction: column;
                    overflow-y: auto;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    height: 110%;
                    padding: 5%;
                    box-shadow: 0px 0px 11px -2px rgba(0,0,0,0.75);                
                    .additional-body{
                        font-size: x-large;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        height: 100%;
                        width: 100%;
                    }
                    .about-online-wrapper{
                        height: 100%;
                        width: 100%;
                        display: grid;
                        grid-template-columns: 1fr;
                        grid-template-rows: auto;
                        justify-content: center;
                        align-items: center;
                        margin: 5%;
                        h2{
                            font-size: 0.9em;
                            margin: 1%;
                        }
                        .about-online-link{
                            width: 100%;
                            margin: 2%;
                        }
                    }
                }

            
        }
        .about-right-column{
            width: 100%;
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: 1fr 4fr;
            grid-template-areas: 
                "IMG"
                "Work";
            grid-area: rightcol;
            gap: 4%;
            height: 84vh;
            padding: 5%;
            @media only screen and (max-aspect-ratio : 1.05){
                gap: 2%;
            }
            img{
                width: 100%;
                filter: grayscale(1);
                opacity: 0.85;
                grid-area: IMG;
                box-shadow: 0px 0px 11px -2px rgba(0,0,0,0.75);
                
            }
            .about-work-wrapper{
                grid-area: Work;
                height: 105%;
                box-shadow: 0px 0px 11px -2px rgba(0,0,0,0.75);                padding: 5%;
                overflow-y: auto;
                display: flex;
                flex-direction: column;
 
                @media only screen and (max-aspect-ratio : 1.05){
                    height: 96%;
                }
                .about-work-title-wrapper{
                    font-size: x-large;
                    place-self: center center;
                }
                .about-work-item{
                    h2{
                        font-size: 1.3em;
                    }
                    h4{
                        font-style: italic;
                    }
                    h6{
                        font-size: 0.8em;
                    }
                }
            }
        }
    }
}
