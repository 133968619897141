.article-item-wrapper{
    display: grid;
    grid-row: 2/4;
    grid-column: 1/3;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
    "article details";
    height: 100%;
    width: 100%;
    .article-item-content{
        display: flex;
        grid-area: article;
        height: 78dvh;
        width: 40dvw;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        flex-direction: column;
        overflow: hidden;
        .article-content-frame{
            display: flex;
            width: 36dvw;
            height: 75dvh;
            margin-bottom: 3%;
            overflow: hidden;
        }
        .article-visit-link{
            color: black;
            font-family: 'Caveat', cursive;
            font-weight: 600;
            padding: 1%;
            border: solid 0.5px black;
            font-size: 1.5rem;
        }
    }
    .article-item-info{
        display: flex;
        grid-area: details;
        flex-direction: column;
        padding: 5%;
        align-items: center;
        font-family: "Indie Flower", cursive;
        .article-item-title{
            font-size: 2.5vh;
            padding: 2%;
            font-family: "Shadow into Light", cursive;
        }
        .article-item-date{
            font-size: 2.3vh;
        }
        .article-item-language{
            font-size: 2.1vh;
        }
        .article-item-description{
            font-size: 2.3vh;
            padding: 5%;
            text-align: center;
            
        }
    }

}


