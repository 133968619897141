
@import "../../variables.scss";

.portfolio-item-wrapper{
    @include popover(fixed, $menu-background-color: #bdbdbd );
    background-image: url("../../../helper/images/backgrounds/bookspine.png");
    display: flex;
    background-repeat: no-repeat;
    background-size: cover;
    height: 95%;
    background-position: center;
    justify-content: center;
    align-items: center;
    width: 7%;
    position: relative;
    flex-direction: column-reverse;
    align-self: flex-end;
    @media only screen and (max-aspect-ratio: 1.05){
        height: 95%;
        width: 9%;
    }
};
.menu{
    .menu-content{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 15vw;
        .preview-desc{
            text-overflow: ellipsis;
            overflow : hidden;
        }
        .preview-image{
            width: 100%;
            padding: 2%;
        }
        .preview-name{
            font-size: 0.85em;
        }
    }
}



.volume-id{
    display: flex;
    justify-content: center;
    align-items: center;
    color: slategray;
    font-size: smaller;
    grid-area: 2/1/3/2;
    margin-top: 2%;
};

.portfolio-item-name{
    display: flex;
    rotate: 90deg;
    font-size: small;
    font-family: 'Indie Flower', cursive;
    font-weight: bold;
    color: slategray;
    grid-area: 4/1/5/2; 
};

.portfolio-binder-wrapper{
    display: grid;
    flex-direction: column;
    align-items: center;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 2.2fr 2.5fr 13.4fr 2.3fr;
    height: 100%;
}

.plaque-contents{
    background-image: url("../../../helper/images/backgrounds/lightpencilbackground.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: fill;
    font-weight: bold;
    opacity: 0.75;   
}
.Projects{
    @include popover(above, $menu-background-color: #bdbdbd );
    display: flex;
}
.Hobbies{
    @include popover(above, $menu-background-color: #bdbdbd );
    display: flex;
}
.menu-content{
    width: 33vw;
    text-align: center;
    img{
        width: 100%;
    }
}
