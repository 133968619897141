.shelf-hor{
    display: flex;
    background-image: url("../../../helper/images/backgrounds/woodgrainhorizontal.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    color: black;
    text-shadow: 2px 8px 6px rgba(0,0,0,0.2), 0px -5px 35px rgba(255,255,255,0.3), 0px 0px 20px rgba(255,255,255,0);
    opacity: 0.85;
    @media only screen and (max-aspect-ratio: 1.05){
      opacity: 1;
    }
};
.shelf-vert{
    background-image: url("../../../helper/images/backgrounds/woodgrainvert.jpg");
    background-position: center;
    background-size: auto 100%;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    opacity: 0.85;
    @media only screen and (max-aspect-ratio: 1.05){
      opacity: 1;
    }
};
.plaque{
    display: flex;
    background-image: url("../../../helper/images/backgrounds/plaque.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    place-self: center;
    place-items: center;
    max-width: 75%;
    min-width: 25%;
    max-height: 80%;
    align-items: center;
    justify-content: center;
    border: solid 1px black;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    padding: 0.5%;
};
.bookhole{
  display: flex;
  background-image: url("../../../helper/images/backgrounds/darkpencilbackground.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;  
  border: 1px dotted black;
  box-shadow: inset 0px -1px 59px -14px #000000;
}
#portfolio-wrapper{
  display:flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  grid-area: 2/2/3/3;

}
#portfolio-container-wrapper{
  display: grid;
  width: 75%;
  align-items: center;
  justify-content: center;
  grid-template-columns: 1fr 10fr 1fr;
  grid-template-rows: 0.5fr 10fr 1fr;
  height: 98%;
  border: 1px solid black;
  @media only screen and (max-aspect-ratio : 1.05){
    grid-template-columns: 1fr 22fr 1fr;
    grid-template-rows : 1fr 34fr 1.5fr;
    height: 100%;
    width: 90%;
  }
};
#portfolio-container-topbar{
    grid-area: 1 / 1 / 2 / 4;
  };
  #portfolio-title-placard{
    display: flex;
    justify-content: center;
    align-items: center;
  };
  #portfolio-sidebar-left{
    display: grid;
    grid-area: 2 / 1 / 10 / 2;
  };
  #portfolio-sidebar-right{
    display: grid;
    grid-area: 2 / 3 / 10 / 4;
  };
  #portfolio-body{
    display: grid;
    grid-area: 2 / 2 / 3 / 3;
    grid-template-columns: 1fr;
    grid-template-rows: 4fr 1fr 4fr 1fr 4fr 1fr 4fr;
    height: 100%;
    @media only screen and (max-aspect-ratio: 1.05){
      grid-template-rows: 8fr 1fr 8fr 1fr 8fr 1fr 8fr;
    }
};
  #portfolio-bookhole-1{
    display: flex;
    grid-area: 1 / 1 / 2 / 2;
  };
  #portfolio-body-rail-1{
    display: flex;
    grid-area: 2 / 1 / 3 / 2;
  };
  #portfolio-bookhole-2{
    display: flex;
    grid-area: 3 / 1 / 4 / 2;
  };
  #portfolio-body-rail-2{
    display: flex;
    grid-area: 4 / 1 / 5 / 2;
  
  };
  #portfolio-bookhole-3{
    display: flex;
    grid-area: 5 / 1 / 6 / 2;
  };
  #portfolio-body-rail-3{
    display: flex;
    grid-area: 6 / 1 / 7 / 2;
  };
  #portfolio-bookhole-4{
    display: flex;
    grid-area: 7 / 1 / 8 / 2;
  };
  #portfolio-container-bottombar{
    display: flex;
    grid-area: 3 / 2 / 4 / 3;
    height: 100%;
  };
  .Web{
    grid-area: 1 / 1 / 2 / 2;
  }   
  .Software{
    grid-area: 3 / 1 / 4 / 2;
  }; 
  .DataScience{
    grid-area: 5 / 1 / 6 / 2;
  };
  .Articles{
    grid-area: 7 / 1 / 8 / 2;
  };

  #portfolio-fork-wrapper{
    display:flex;
    height: 100%;
    width: 100%;
    align-items: flex-start;
    justify-content: center;
  }
.description-box{
  display: flex;
  flex-direction: column;
  height: 50dvh;
  padding: 3%;
  margin: 5%;
  font-size: 3vh;
  box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.75);
  overflow-y: auto;
}