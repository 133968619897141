#root{
    height: 100dvh;
    
}
#root-wrapper{
    display: flex;
    align-items: center;
    height: 100dvh;
    flex-direction: column;

}
#biography-blurb{
    grid-area: 2/1/3/2;
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: 1fr;
    grid-template-rows: 3fr 1fr 2fr;
    grid-template-areas: "pic" "button" "bio";
    margin: 10%;
    flex-direction : column;
    font-family: 'Caveat', cursive;
    text-align: center;
    height: 120dvh;
    p{
        grid-area: bio;
        overflow-y: auto;
        overflow-x: clip;
        text-align: center;
        height: 40dvh;
    }
    .self-destruct-button{
        grid-area: button;
        align-items: center;
        justify-self: center;
        padding: 5%;
        background-color: gray;
        color: lightgray;
        border-radius: 50%;
        box-shadow: 0px 11px 15px 7px rgba(0,0,0,0.75);
        cursor: pointer;
        width: 7dvw;
        text-align: center;
        border: black 1px dotted;
        font-family:'Courier New', Courier, monospace;
        @media screen and (max-aspect-ratio: 1.3){
            width: 12dvw;
        }
    }
    .self-destruct-button:hover{
        background-color: darkred;
        opacity: 0.8;
    }
    @media only screen and (max-aspect-ratio: 1.05){
        margin: 8%;
        padding-top: 15%;
    }
    

    img{
        width: auto;
        height: 50dvh;
        grid-area: pic;
        filter: grayscale(1);
        margin-bottom: 5%;
        margin-top: 5%;
        opacity: 0.75;
        justify-self: center;
        @media only screen and (max-aspect-ratio : 1.05){
            margin-right: 20%;
            margin-top: -30%;
        }
    }
    p{
        font-size: x-large;
        margin-left: 5%;
        @media only screen and (max-aspect-ratio : 1.05){
            margin-right: 5%;
            font-size: larger;
        }
    }
}

#root-content-wrapper{
    height: 75%;
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 1fr 6fr 1fr;
    
}