.clickable{
    cursor: pointer;
}
html{
    height: 100%;
    background-image: url("../helper/images/backgrounds/wordcloudbackground.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;  
    background-attachment: fixed;
    @media only screen and (max-aspect-ratio: 1.05){
        overflow-y: auto;   
    }
}
body{
    height: 100%;
    margin: 0%;
    
}

.Emph{
    transition: 0.25s;
    box-shadow: 0px 0px 13px 0px rgba(0,0,0,0.75);
}