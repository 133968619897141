#top-bar-wrapper{
    display: flex;
    height: 20%;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-top: 3%;
    font-family: "Shadows into Light", cursive;
}
#logo{
    width: 37%;
    opacity: 0.6;
    margin-left: 5%;
    margin-top: 2%;
    place-self: center center;
    filter: grayscale(1) ; 
    @media only screen and (max-aspect-ratio : 1.05){
        width: 25%;

    }
}

#identification-blurb{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height: 75%;
    margin: 20%;
    margin-bottom: 25%;
    flex-direction: column;
    padding-top: 8%;
    @media only screen and (max-aspect-ratio : 1.05){
        margin-left: 0%;
    }
}
#nav-bar-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
}
#nav-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 100%;
}
#nav-symbol{
    display: flex;
}
.icon-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}
.contact-link{
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Shadows into Light', cursive;
    width: 50%;
    p{
        margin: 5%;
    }
    .icon{
        margin: 5%;
        font-size: large;
    }
}
#name-tag{
    font-size: x-large;
    margin-bottom: 0;
    
}
.nav-link{
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 3%;
    border: 2px black solid;
    border-radius: 10%;
    background-color: #D2D0cF;
    box-shadow: 0px 3px 10px -4px rgba(0,0,0,0.75);
}
.nav-link:visited{
    color: grey;
}
