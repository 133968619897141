#mobile-identification-blurb{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.mobile-contact-link{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: "Shadows into Light", cursive;
    width: 100%;
    
    p{
        display: flex;
        flex-wrap: nowrap;
        width: 45%;
        align-items: center;
        justify-content: center;
    }
}
.mobile-icon-wrapper{
    display: flex;
    flex-direction: column;
    padding: 8%;
    color: grey;
}

#mobile-name-tag{
    font-size: x-large;
    font-family: "Caveat", cursive;
}
