.loader {
    background: radial-gradient(ellipse, rgba(217,216,216,1) 0%, rgba(169,169,171,0.8004640371229699) 46%, rgba(92,90,90,0.728538283062645) 100%);
    text-align:center;
    height: 50%;
    display:flex;
    align-items:center;
    justify-content:center;
    width: 50%;
}
.loader h1 {
    color: slategray;
    font-family: 'Caveat', cursive;
    font-weight: 800;
    font-size: 4em;
}
.bullets{
    animation: dots 2s steps(3, end) infinite;
}
    
@keyframes dots {
    0%, 20% {
    color: slategray;
    text-shadow:
          .25em 0 0 rgba(0,0,0,0),
          .5em 0 0 rgba(0,0,0,0);}
      40% {
        color: whitesmoke;
        text-shadow:
          .25em 0 0 rgba(0,0,0,0),
          .5em 0 0 rgba(0,0,0,0);}
      60% {
        text-shadow:
          .25em 0 0 whitesmoke,
          .5em 0 0 rgba(0,0,0,0);}
      80%, 100% {
        text-shadow:
          .25em 0 0 whitesmoke,
          .5em 0 0 whitesmoke;}}
    