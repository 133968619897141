.mobile-project-wrapper{
    display: grid;
    grid-template-rows: 0.5fr 0.5fr 0.5fr 0.7fr 4.6fr;
    grid-template-columns: 1fr;
    grid-template-areas:
        "title"
        "date"
        "language"
        "links"
        "description";
    height: 100%;
    width: 100%;
    padding: 3%;
    .mobile-project-title{
        display: flex;
        grid-area: title;
        font-size: 4.5vh;
        align-items: center;
        justify-content: center;
        font-family: "Indie Flower", cursive;
        width:100%;
    }
    .mobile-project-date{
        display: flex;
        grid-area: date;
        padding: 1%;
        font-size: 3vh;
        font-family: "Indie Flower", cursive;
        align-items: center;
        justify-content: center;
    }
    .mobile-project-language-wrapper{
        display: grid;
        grid-area: language;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr;
        grid-template-areas: 
            "language langfill languagedet";
        font-family: 'Caveat', cursive;
        .mobile-project-language{
            display: flex;
            grid-area: language;
            align-items: center;
            justify-content: center;
        }
        .mobile-project-languagedetail{
            display: flex;
            grid-area: languagedet;
            align-items: center;
            justify-content: center;
        }
    }
    .mobile-project-links-wrapper{
        display:grid;
        grid-area: links;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr;
        grid-template-areas:
            "project sole repo";
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        font-size: 4vh;
        font-family: "Caveat", cursive;
        order: 2;
        .link{
            color: slategray;
            text-decoration: none;
            padding: 1%;
            text-align: center;
        }
        .mobile-project-item-link-wrapper{
            display:  flex;
            grid-area: project;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: 100%;
            order: 2;
            background: radial-gradient(ellipse, rgba(251,251,251,.75) 0%, rgba(229,229,238,0.5) 46%, rgba(213,211,211,0.25) 75%);
            border-radius: 30%;
        }
        .mobile-project-item-repo-link-wrapper{
            display: flex;
            grid-area: repo;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: 100%;
            order: 2;
            color: black;
            background: radial-gradient(ellipse, rgba(251,251,251,.75) 0%, rgba(229,229,238,0.5) 46%, rgba(213,211,211,0.25) 75%);
        }
    }
    .mobile-project-item-sole-link-wrapper{
        display: flex;
        grid-area: sole;
        height: 100%;
        width: 100%;
    }
    .mobile-project-item-description{
        display: flex;
        grid-area: description;
        height: 45vh;
        width: 100%;
        padding: 7%;
        align-items: flex-start;
        justify-content: center;
        font-family: "Caveat", cursive;
        font-size: 120%;
        order: 2;
        overflow-y: scroll;
        box-shadow: -2px 7px 31px -2px rgba(0,0,0,0.6);
    }
    .mobile-project-image{
        display: flex;
        position: absolute;
        width: 100%;
        height: 80%;
        grid-row: 1/6;
        grid-column: 1/2;
        opacity: 0.3;
        order: 0;
        z-index: -1;
    }
}
.mobile-project-link-fork-wrapper{
    display:grid;
    grid-area: links;
    align-items: center;
    justify-content: center;
}

.mobile-project-language-article{
    display: flex;
    grid-row: 1/2;
    grid-column: 1/4;
    justify-content: center;
    align-items: center;
}
.mobile-project-article-link{
    display: flex;
    grid-row: 4/5;
    grid-column: 1/2;
    background: radial-gradient(ellipse, rgba(251,251,251,.75) 0%, rgba(229,229,238,0.5) 46%, rgba(213,211,211,0.25) 75%);
    align-items: center;
    justify-content: center;
    height: 75%;
    .article-link{
        color: slategrey;
        font-size: 150%;
        font-family: "Caveat", cursive;
    }

}