@mixin popover(
  $menu-position         : fixed,              
  $menu-width            : 400,              
  $menu-background-color : rgb( 255,255,255 ), 
  $nubbin-size           : 30,               
  $menu-hidden-distance  : 20,               
  $menu-shadow-color     : rgba(0,0,0,0.3),    
  $menu-border-radius    : 0,                 
  $is-menu               : true                
  ) {
  display: inline-block;
  float: left;
  position: relative;
  &:hover {
    cursor: pointer;
    > .menu {
      @extend .menu-show;
      @if $menu-position == above {
        top: 0;
      }
      @if $menu-position == below {
        bottom: 0;
        right: calc(-1 * $menu-width)px;
      }
      @if $menu-position == left {
        left: 0;
      }
      @if $menu-position == right {
        right: calc(-1 * $menu-width)px;
      }
      @if $menu-position == fixed {
        left: (-20vw - #{$menu-width}px);

      }
    }
  }
  .menu-hidden {
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s ease-in-out;
  }
  .menu-show {
    opacity: 1;
    visibility: visible;
  }
  .menu {
    width: ($menu-width)px;
    @extend .menu-hidden;
    position: absolute;
    z-index: 2;
    @if $menu-position == above {
      padding-bottom: ($nubbin-size)px;
      top: (0 - $menu-hidden-distance)px;
      left: 50%;
      transform: translate(-50%, -100%);
    }
    @if $menu-position == below {
      padding-top: ($nubbin-size)px;
      bottom: (0 - $menu-hidden-distance)px;
      left: 50%;
      transform: translate( -50%, 100% );
      right: (-1 * ($menu-width + $nubbin-size) - $menu-hidden-distance)px;
    }
    @if $menu-position == left {
      padding-right: ($nubbin-size)px;
      top: 50%;
      left: (0 - $menu-hidden-distance)px;
      transform: translate( -100%, -50%);
    }
    @if $menu-position == right {
      padding-left: ($nubbin-size)px;
      top: 50%;
      right: (-1 * ($menu-width + $nubbin-size) - $menu-hidden-distance)px;
      transform: translate(0,-50%);
    }
    @if $menu-position == fixed{
      padding-right: ($nubbin-size)px;
      left: (-20vw - $menu-hidden-distance)px;
      transform : translate(-30vw, -6vh);

    }
    &:hover > .menu-content {
      @if $is-menu == true {
        box-shadow: 0 8px 12px 5px lighten( ($menu-shadow-color), 40% );
      }
      @else {
        box-shadow: none;
      }
    } 
    .menu-content:before {
      content: "";
      width: 0;
      height: 0;
      border-width: ($nubbin-size + 2)px;
      border-style: solid;
      position: fixed;
      @if $menu-position == above {
        bottom: -2px;
        left: calc($menu-width / 2)px;
        margin-left: calc(($nubbin-size + 2)*-1)px;
        border-top-color: lighten($menu-shadow-color, 70%);
        border-right-color: transparent;
        border-bottom-width: 0;
        border-left-color: transparent;  
      }
      @if $menu-position == below {
        top: -2px;
        left: calc( $menu-width / 2 )px;
        margin-left: calc(-1*($nubbin-size + 2))px;
        border-top-width: 0;
        border-right-color: transparent;
        border-bottom-color: lighten($menu-shadow-color, 70%);
        border-left-color: transparent; 
      }
      @if $menu-position == left {
        top: 50%;
        right: -2px;
        margin-top: calc(-1*$nubbin-size - 2)px;
        border-top-color: transparent;
        border-right-width: 0;
        border-bottom-color: transparent;
        border-left-color: lighten($menu-shadow-color, 70%); 
      }
      @if $menu-position == right {
        top: 50%;
        left: -2px;
        margin-top: calc(-1* $nubbin-size - 2)px;
        border-top-color: transparent;
        border-right-color: lighten($menu-shadow-color, 70%);
        border-bottom-color: transparent;
        border-left-width: 0;
      }
      @if $menu-position == fixed {
        top: 50%;
        right: -20px;
        margin-top: calc(-1*$nubbin-size - 2)px;
        border-top-color: transparent;
        border-right-width: 0;
        border-bottom-color: transparent;
        border-left-color: lighten($menu-shadow-color, 70%); 
      }
    }
    .menu-content:after {
      content: "";
      width: 0;
      height: 0;
      border-width: ($nubbin-size)px;
      border-style: solid;
      position: absolute;
      @if $menu-position == above {
        bottom: 0;
        left: calc( $menu-width / 2 )px;
        margin-left: (-1 * $nubbin-size)px;
        border-top-color: $menu-background-color;
        border-right-color: transparent;
        border-bottom-width: 0;
        border-left-color: transparent;  
      }
      @if $menu-position == below {
        top: 0;
        left: calc($menu-width / 2)px;
        margin-left: (-1 * $nubbin-size)px;
        border-top-width: 0;
        border-right-color: transparent;
        border-bottom-color: $menu-background-color;
        border-left-color: transparent; 
      }
      @if $menu-position == left {
        top: 50%;
        right: 0;
        margin-top: (-1 * $nubbin-size)px;
        border-top-color: transparent;
        border-right-width: 0;
        border-bottom-color: transparent;
        border-left-color: $menu-background-color; 
      }
      @if $menu-position == right {
        top: 50%;
        left: 0;
        margin-top: (-1 * $nubbin-size)px;
        border-top-color: transparent;
        border-right-color: $menu-background-color;
        border-bottom-color: transparent;
        border-left-width: 0;
      }
      @if $menu-position == fixed {
        top: 50%;
        right: 0;
        margin-top: (-1 * $nubbin-size)px;
        border-top-color: transparent;
        border-right-width: 0;
        border-bottom-color: transparent;
        border-left-color: $menu-background-color; 
      }
    }
    .menu-content {
      margin: 0;
      padding: 5px;
      list-style: none;
      line-height: 1.4rem;
      background-color: $menu-background-color;
      box-shadow: 0 0 2px 0 $menu-shadow-color;
      border-radius: $menu-border-radius;
      transition: all 0.3s ease-in-out;
      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        li {
          &:first-child {
            border-top-left-radius: $menu-border-radius;
            border-top-right-radius: $menu-border-radius;
          }
          &:last-child {
            border-bottom-left-radius: $menu-border-radius;
            border-bottom-right-radius: $menu-border-radius;
          }
        }
      }
    }
  }
}
$black: #000;
$grey: #ccc;
$white: #fff;
$yellow: #ff0;
$green: #8bc34a;
$blue: #29d2e4;
$orange: #f87d09;
$dark-pink: #e91e63;
$light-purple: #efedff;
//Primary-color
$tooltip-base-text-color: $white;
$tooltip-base-bg-color: $black;
$tooltip-base-border-color: $black;
$main-background: $light-purple;
//Types
$tooltip-invert-bg-color: $grey;
$tooltip-success-bg-color: $green;
$tooltip-info-bg-color: $blue;
$tooltip-warning-bg-color: $orange;
$tooltip-danger-bg-color: $dark-pink;
//Features

*,
:after,
:before {
  box-sizing: border-box;
}


ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  li {
    display: inline-block;
    margin-right: 30px;
  }
}

.tooltip pre {
  background: $white;
  padding: 10px;
  border-radius: 4px;
  &:nth-child(2){
    margin-bottom: 40px;
  }
}

p strong {
  color: $dark-pink;
}

.title {
  text-align: center;
  margin: 40px 0;
}

h3 {
  margin: 10px 0;
}

.preview-title {
  margin: 40px 0 20px;
  font-size: 24px;
  font-weight: normal;
}

.tooltip-content {
  max-width: 960px;
  margin: 0 auto;
  padding: 30px 60px;
  > span {
    font-size: 24px;
    display: block;
    &:not(:first-child) {
      margin-top: 10px;
    }
  }
}

[data-tooltip] {
  position: relative;
  &:before,
  &:after {
    opacity: 0;
    position: absolute;
    left: 50%;
    backface-visibility: hidden
  }
  &:before {
    border-style: solid;
    border-top-width: 4px;
    border-right-width: 4px;
    border-bottom-width: 4px;
    border-left-width: 4px;
    border-bottom-width: 0;
    content: '';
    top: -2px;
    width: 0;
    height: 0;
    transform: translate(-50%, calc(-50% - 6px));
    transition: opacity .1s cubic-bezier(.73, .01, 0, 1) 0s, transform .6s cubic-bezier(.73, .01, 0, 1) 0s, -webkit-transform .6s cubic-bezier(.73, .01, 0, 1) 0s;
    z-index: 110000
  }
  &:after {
    content: attr(data-tooltip);
    text-align: center;
    padding: 10px 10px 9px;
    font-size: 16px;
    border-radius: 8px;
    color: $tooltip-base-text-color;
    transition: opacity .3s cubic-bezier(.73, .01, 0, 1), transform .3s cubic-bezier(.73, .01, 0, 1), -webkit-transform .3s cubic-bezier(.73, .01, 0, 1);
    pointer-events: none;
    z-index: 999;
    white-space: nowrap;
    bottom: 100%;
    transform: translate(-50%, 12px);
    max-width: 320px;
    text-overflow: ellipsis;
    overflow: hidden
  }
}

[data-tooltip]:after {
  background: $tooltip-base-bg-color;
}

[data-tooltip]:before {
  border-top-color: $tooltip-base-bg-color;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent
}

[data-tooltip][data-tooltip-conf*=right]:before {
  border-top-color: transparent;
  border-right-color: $tooltip-base-border-color;
  border-bottom-color: transparent;
  border-left-color: transparent
}

[data-tooltip][data-tooltip-conf*=bottom]:before {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: $tooltip-base-border-color;
  border-left-color: transparent
}

[data-tooltip][data-tooltip-conf*=left]:before {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: $tooltip-base-border-color;
}

[data-tooltip] {
  &:focus,
  &:hover {
    &:before,
    &:after {
      opacity: 1;
    }
  }
  &:focus,
  &:hover {
    &:before {
      transition: opacity .1s cubic-bezier(.73, .01, 0, 1) .1s, transform .6s cubic-bezier(.73, .01, 0, 1) .1s, -webkit-transform .6s cubic-bezier(.73, .01, 0, 1) .1s;
      transform: translate(-50%, calc(-50% - 2px));
    }
    &:after {
      transform: translate(-50%, -6px);
    }
  }
}

[data-tooltip][data-tooltip-conf*=right]:before {
  border-style: solid;
  border-top-width: 4px;
  border-right-width: 4px;
  border-bottom-width: 4px;
  border-left-width: 4px;
  border-left-width: 0;
  left: auto;
  right: -6px;
  top: 50%;
  transform: translate(calc(-50% + 7px), -50%)
}

[data-tooltip][data-tooltip-conf*=right]:after {
  top: 50%;
  left: 100%;
  bottom: auto;
  -webkit-transform: translate(-12px, -50%);
  transform: translate(-12px, -50%)
}

[data-tooltip][data-tooltip-conf*=right]:focus:before,
[data-tooltip][data-tooltip-conf*=right]:hover:before {
  -webkit-transform: translate(calc(-50% + 3px), -50%);
  transform: translate(calc(-50% + 3px), -50%)
}

[data-tooltip][data-tooltip-conf*=right]:focus:after,
[data-tooltip][data-tooltip-conf*=right]:hover:after {
  -webkit-transform: translate(7px, -50%);
  transform: translate(7px, -50%)
}

[data-tooltip][data-tooltip-conf*=bottom]:before {
  border-style: solid;
  border-top-width: 4px;
  border-right-width: 4px;
  border-bottom-width: 4px;
  border-left-width: 4px;
  border-top-width: 0;
  top: auto;
  bottom: -6px;
  left: 50%;
  -webkit-transform: translate(-50%, calc(-50% + 6px));
  transform: translate(-50%, calc(-50% + 6px))
}

[data-tooltip][data-tooltip-conf*=bottom]:after {
  top: 100%;
  left: 50%;
  bottom: auto;
  -webkit-transform: translate(-50%, -12px);
  transform: translate(-50%, -12px)
}

[data-tooltip][data-tooltip-conf*=bottom]:focus:before,
[data-tooltip][data-tooltip-conf*=bottom]:hover:before {
  -webkit-transform: translate(-50%, calc(-50% + 2px));
  transform: translate(-50%, calc(-50% + 2px))
}

[data-tooltip][data-tooltip-conf*=bottom]:focus:after,
[data-tooltip][data-tooltip-conf*=bottom]:hover:after {
  -webkit-transform: translate(-50%, 6px);
  transform: translate(-50%, 6px)
}

[data-tooltip][data-tooltip-conf*=left]:before {
  border-style: solid;
  border-top-width: 4px;
  border-right-width: 4px;
  border-bottom-width: 4px;
  border-left-width: 4px;
  border-right-width: 0;
  left: -2px;
  top: 50%;
  width: 0;
  height: 0;
  -webkit-transform: translate(calc(-50% - 8px), -50%);
  transform: translate(calc(-50% - 8px), -50%)
}

[data-tooltip][data-tooltip-conf*=left]:after {
  top: 50%;
  right: 100%;
  bottom: auto;
  left: auto;
  -webkit-transform: translate(12px, -50%);
  transform: translate(12px, -50%)
}

[data-tooltip][data-tooltip-conf*=left]:focus:after,
[data-tooltip][data-tooltip-conf*=left]:hover:after {
  -webkit-transform: translate(-7px, -50%);
  transform: translate(-7px, -50%)
}

[data-tooltip][data-tooltip-conf*=left]:focus:before,
[data-tooltip][data-tooltip-conf*=left]:hover:before {
  -webkit-transform: translate(calc(-50% - 3px), -50%);
  transform: translate(calc(-50% - 3px), -50%)
}

[data-tooltip][data-tooltip-conf*=multiline]:after {
  word-break: break-word;
  white-space: normal;
  min-width: 180px;
  text-overflow: clip
}

[data-tooltip][data-tooltip-conf*=delay]:before {
  -webkit-transition: opacity .2s cubic-bezier(.73, .01, 0, 1) 0s, -webkit-transform .3s cubic-bezier(.73, .01, 0, 1) 0s;
  transition: opacity .2s cubic-bezier(.73, .01, 0, 1) 0s, -webkit-transform .3s cubic-bezier(.73, .01, 0, 1) 0s;
  transition: opacity .2s cubic-bezier(.73, .01, 0, 1) 0s, transform .3s cubic-bezier(.73, .01, 0, 1) 0s;
  transition: opacity .2s cubic-bezier(.73, .01, 0, 1) 0s, transform .3s cubic-bezier(.73, .01, 0, 1) 0s, -webkit-transform .3s cubic-bezier(.73, .01, 0, 1) 0s
}

[data-tooltip][data-tooltip-conf*=delay]:after {
  -webkit-transition: opacity .3s cubic-bezier(.73, .01, 0, 1) 0s, -webkit-transform .3s cubic-bezier(.73, .01, 0, 1) 0s;
  transition: opacity .3s cubic-bezier(.73, .01, 0, 1) 0s, -webkit-transform .3s cubic-bezier(.73, .01, 0, 1) 0s;
  transition: opacity .3s cubic-bezier(.73, .01, 0, 1) 0s, transform .3s cubic-bezier(.73, .01, 0, 1) 0s;
  transition: opacity .3s cubic-bezier(.73, .01, 0, 1) 0s, transform .3s cubic-bezier(.73, .01, 0, 1) 0s, -webkit-transform .3s cubic-bezier(.73, .01, 0, 1) 0s
}

[data-tooltip][data-tooltip-conf*=delay]:focus:before,
[data-tooltip][data-tooltip-conf*=delay]:hover:before {
  -webkit-transition: opacity .2s cubic-bezier(.73, .01, 0, 1) .5s, -webkit-transform .6s cubic-bezier(.73, .01, 0, 1) .5s;
  transition: opacity .2s cubic-bezier(.73, .01, 0, 1) .5s, -webkit-transform .6s cubic-bezier(.73, .01, 0, 1) .5s;
  transition: opacity .2s cubic-bezier(.73, .01, 0, 1) .5s, transform .6s cubic-bezier(.73, .01, 0, 1) .5s;
  transition: opacity .2s cubic-bezier(.73, .01, 0, 1) .5s, transform .6s cubic-bezier(.73, .01, 0, 1) .5s, -webkit-transform .6s cubic-bezier(.73, .01, 0, 1) .5s
}

[data-tooltip][data-tooltip-conf*=delay]:focus:after,
[data-tooltip][data-tooltip-conf*=delay]:hover:after {
  -webkit-transition: opacity .3s cubic-bezier(.73, .01, 0, 1) .4s, -webkit-transform .3s cubic-bezier(.73, .01, 0, 1) .4s;
  transition: opacity .3s cubic-bezier(.73, .01, 0, 1) .4s, -webkit-transform .3s cubic-bezier(.73, .01, 0, 1) .4s;
  transition: opacity .3s cubic-bezier(.73, .01, 0, 1) .4s, transform .3s cubic-bezier(.73, .01, 0, 1) .4s;
  transition: opacity .3s cubic-bezier(.73, .01, 0, 1) .4s, transform .3s cubic-bezier(.73, .01, 0, 1) .4s, -webkit-transform .3s cubic-bezier(.73, .01, 0, 1) .4s
}

[data-tooltip][data-tooltip-conf*=shadow]:after {
  box-shadow: 0 2px 10px 2px rgba(0, 0, 0, .1)
}

[data-tooltip][data-tooltip-conf*=no-fading]:after,
[data-tooltip][data-tooltip-conf*=no-fading]:before {
  -webkit-transition: none;
  transition: none
}

[data-tooltip][data-tooltip-conf*=no-arrow]:before {
  display: none
}

[data-tooltip][data-tooltip-conf*=square]:after {
  border-radius: 0
}

[data-tooltip][data-tooltip-conf*=invert]:after {
  color: $tooltip-base-text-color;
  background: $tooltip-invert-bg-color;
}

[data-tooltip][data-tooltip-conf*=invert]:before {
  border-top-color: $tooltip-invert-bg-color;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent
}

[data-tooltip][data-tooltip-conf*=invert][data-tooltip-conf*=right]:before {
  border-top-color: transparent;
  border-right-color: $tooltip-invert-bg-color;
  border-bottom-color: transparent;
  border-left-color: transparent
}

[data-tooltip][data-tooltip-conf*=invert][data-tooltip-conf*=bottom]:before {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: $tooltip-invert-bg-color;
  border-left-color: transparent
}

[data-tooltip][data-tooltip-conf*=invert][data-tooltip-conf*=left]:before {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: $tooltip-invert-bg-color;
}

[data-tooltip][data-tooltip-conf*=success]:after {
  background: $tooltip-success-bg-color;
}

[data-tooltip][data-tooltip-conf*=success]:before {
  border-top-color: $tooltip-success-bg-color;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent
}

[data-tooltip][data-tooltip-conf*=success][data-tooltip-conf*=right]:before {
  border-top-color: transparent;
  border-right-color: $tooltip-success-bg-color;
  border-bottom-color: transparent;
  border-left-color: transparent
}

[data-tooltip][data-tooltip-conf*=success][data-tooltip-conf*=bottom]:before {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: $tooltip-success-bg-color;
  border-left-color: transparent
}

[data-tooltip][data-tooltip-conf*=success][data-tooltip-conf*=left]:before {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: $tooltip-success-bg-color;
}

[data-tooltip][data-tooltip-conf*=info]:after {
  background: $tooltip-info-bg-color;
}

[data-tooltip][data-tooltip-conf*=info]:before {
  border-top-color: $tooltip-info-bg-color;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent
}

[data-tooltip][data-tooltip-conf*=info][data-tooltip-conf*=right]:before {
  border-top-color: transparent;
  border-right-color: $tooltip-info-bg-color;
  border-bottom-color: transparent;
  border-left-color: transparent
}

[data-tooltip][data-tooltip-conf*=info][data-tooltip-conf*=bottom]:before {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: $tooltip-info-bg-color;
  border-left-color: transparent
}

[data-tooltip][data-tooltip-conf*=info][data-tooltip-conf*=left]:before {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: $tooltip-info-bg-color;
}

[data-tooltip][data-tooltip-conf*=warning]:after {
  background: $tooltip-warning-bg-color;
}

[data-tooltip][data-tooltip-conf*=warning]:before {
  border-top-color: $tooltip-warning-bg-color;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent
}

[data-tooltip][data-tooltip-conf*=warning][data-tooltip-conf*=right]:before {
  border-top-color: transparent;
  border-right-color: $tooltip-warning-bg-color;
  border-bottom-color: transparent;
  border-left-color: transparent
}

[data-tooltip][data-tooltip-conf*=warning][data-tooltip-conf*=bottom]:before {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: $tooltip-warning-bg-color;
  border-left-color: transparent
}

[data-tooltip][data-tooltip-conf*=warning][data-tooltip-conf*=left]:before {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: $tooltip-warning-bg-color;
}

[data-tooltip][data-tooltip-conf*=danger]:after {
  background: $tooltip-danger-bg-color;
}

[data-tooltip][data-tooltip-conf*=danger]:before {
  border-top-color: $tooltip-danger-bg-color;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent
}

[data-tooltip][data-tooltip-conf*=danger][data-tooltip-conf*=right]:before {
  border-top-color: transparent;
  border-right-color: $tooltip-danger-bg-color;
  border-bottom-color: transparent;
  border-left-color: transparent
}

[data-tooltip][data-tooltip-conf*=danger][data-tooltip-conf*=bottom]:before {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: $tooltip-danger-bg-color;
  border-left-color: transparent
}

[data-tooltip][data-tooltip-conf*=danger][data-tooltip-conf*=left]:before {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: $tooltip-danger-bg-color;
}

[data-tooltip][data-tooltip=''] {
  &:after,
  &:before {
    display: none
  }
}
nav {
  &.primary-navigation {
    display: flex;  
    text-align: center;
    font-size: 24px;
    align-items: center;
    justify-content: center;    
  
    

    ul li {
      list-style: none;
      margin: 0 auto;
      display: inline-block;
      padding: 0 30px;
      position: relative;
      text-decoration: none;
      text-align: center;
      font-family: arvo;
      
    }

    li a {
      color: black;
    }

    li a:hover {
      color: slategray;
    }

    li:hover {
      cursor: pointer;
    }

    ul li ul {
      visibility: hidden;
      opacity: 0;
      position: absolute;
      left: 0;
      display: block;
      background: #c9cccf;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-top: 20px;
      box-shadow: 0px 3px 5px -1px #ccc;
      margin-right: 10%;
    }

    ul li:hover > ul,
    ul li ul:hover {
      visibility: visible;
      opacity: 1;
      position: absolute;
      left: 0;
      display: block;
      background: #c9cccf;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-top: 20px;
      box-shadow: 0px 3px 5px -1px #ccc;
    }

    ul li ul li {
      clear: both;
      width: 100%;
      text-align: left;
      margin-bottom: 20px;
      border-style: none;
    }

    ul li ul li a:hover {
      transition: all 0.3s ease;
    }
  }
}

a {

    text-decoration: none;

    &:hover {
        color: slategray;
    }
 
}

 ul li ul li a { transition: all 0.5s ease; }

